<template>
  <DataList
    resource="shipping-drafts"
    :headers="headers"
    title="Черновики"
    :disable-adding="false"
    :allow-delete="true"
    :filter-options="filter"
    store="shippingDrafts"
    :add-route="{ name: 'shipping-new-steps' }"
    row-to="shipping-new-steps"
  />
</template>

<script>
import DataList from "../../components/DataList.vue";

export default {
  components: {
    DataList,
  },
  data() {
    return {
      headers: [
        { text: "Дата создания", sortable: true, type: "date", name: "date", width: "30", maxLength: 10, dataPath: "date" },
        { text: "Менеджер", sortable: false, name: "manager", width: "120", maxLength: 8, dataPath: "manager.title" },
        {
          text: "Страна отправления", sortable: true, name: "country", type: "object", width: "100", maxLength: 10, valueFn(i) {
            return i.country;
          },
        },
        {
          text: "Продавец", sortable: true, type: "object", name: "senderFactory", width: "110", maxLength: 10, valueFn(i) {
            return i.senderFactory;
          },
        },
      ],
      filter: {
        date: {
          title: "Дата",
          type: "date",
          isManualEdit: true,
        },
        country: {
          title: "Страна отправления",
          type: "reference",
          resource: "countries",
        },
        senderFactory: {
          title: "Продавец",
          type: "reference",
          resource: "factories",
        },
      },
    };
  },
};
</script>